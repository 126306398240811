import { Component, OnInit ,Inject,AfterViewInit,Directive, QueryList, ViewChild,ElementRef} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/dialogs/confirm/confirm.component';
import { ConstantesService } from '../../services/constantes.services';
import { DataService } from '../../services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}
enum enumState {
  start = 1,
  registroVerificado = 2, // ESTO ES QUE EL NUMERO REGISTRO SI EXISTE Y NO HA SIDO CONFIRMADO
  registroInexistente = 3, // EL NUMERO REGISTRO NO EXISTE
  registroConfirmado = 4, // EL NUMERO DE REGISTRO YA FUE CONFIRMADO

}


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  @ViewChild("myElem") myScrollContainer: ElementRef;
  public ownerForm: FormGroup;


  title = 'vacuna-influenza';
  registro = '';
  periodoAbierto=false;
  favoriteSeason: string;
  seasons: string[] = ['Si', 'No'];
  seleccionado = false
  seleccionT_si = false
  seleccionD_si = false
  registro_valido = false
  registro_consultado = false
  registro_confirmado = false

  arrEdades =[]
  arrSedes =[]
  arrSedesT =[]
  arrSedesD =[]
  arrVacunas =[]
  arrSedesEstadosD =[]
  arrSedesEstadosT =[]
  arrSedesCiudadesT =[]
  arrSedesCiudadesD =[]
  arrSedesCiudadesBaseT =[]
  arrSedesCiudadesBaseD =[]
  arrSedesFechasT =[]
  arrSedesFechasD =[]

  arrFechasT =[]
  arrFechasD =[]
  arrDependientes= []
  arrVacunados = []
  sedeNombreEstado = ''
  sedeNombreCiudad = ''
  showNo = false
  currentState  = enumState.start
  totalVacunas: number = 0
  emailIsValid = false
  messageError ="Verifica el correo electrónico"
  isLoading = false
  ver = 'v.2023-014'
  constructor( public dialog: MatDialog,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    ) {
      // this.getEdades();

      this.cargaSedes();
      this.cargaSedesEstados();
      this.cargaFechas()
      this.cargaSedesCiudades()
    }

    ngOnInit() {
      this.ownerForm = new FormGroup({
        Id_Vi_SedeT: new FormControl('' ),
        Id_Vi_SedeD: new FormControl('' ),
        // registro: new FormControl('90000001', [Validators.required]),
        // email: new FormControl('emartinez@ensamble.dev', [Validators.required]),
        registro: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        sedeNombreEstadoT: new FormControl('', [Validators.required]),
        sedeNombreCiudadT: new FormControl('', [Validators.required]),
        sedeNombreEstadoD: new FormControl('', [Validators.required]),
        sedeNombreCiudadD: new FormControl('', [Validators.required]),
        numeroVacunasT: new FormControl('1', [Validators.required]),
        numeroVacunasD: new FormControl('1', [Validators.required]),
        respuestaT: new FormControl('', [Validators.required]),
        respuestaD: new FormControl('', [Validators.required]),
        fechaT: new FormControl('', [Validators.required]),
        fechaD: new FormControl('', [Validators.required]),

      });
    }
    ngAfterViewChecked() {
      this.scrollToBottom();
    }
    scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) { }
    }
    cargaFechas(){
      let args = {
        ClassName: "VI_Sedes_Fechas",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 1,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrFechasT = response.data;

        }
      });

      args = {
        ClassName: "VI_Sedes_Fechas",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 2,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrFechasD = response.data;

        }
      });

    }

    cargaDependientes(){
      let me = this
      let args = {
        ClassName: "VI_Registro_Incisos",
        Action: "Get",
        Registro: me.ownerForm.get('registro').value,

      };
      me.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          me.arrDependientes = response.data;
          me.arrDependientes.map(function(dato){
              let argsDependientes = {
                ClassName: "VI_Registro_Incisos_Recibe_Vacuna",
                Action: "Update",
                Recibe_Vacuna : 0,
                Id_Vi_Registro_Incisos: dato.Id_Vi_Registro_Incisos,
              };

              me.DataSrv.executeForCRUD(argsDependientes).subscribe(response => {
                console.log(response)
              })

          });
          // console.log(me.arrDependientes)
        }
      });




    }
    cargaSedes(){
      let args = {
        ClassName: "VI_Sedes",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 1,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesT = response.data;

        }
      });

      args = {
        ClassName: "VI_Sedes",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 2,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesD = response.data;

        }
      });

    }
    cargaSedesEstados(){

      let args = {
        ClassName: "VI_Sedes_Estados",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 1,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesEstadosT = response.data;
        }
      });

      args = {
        ClassName: "VI_Sedes_Estados",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 2,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesEstadosD = response.data;
        }
      });

    }
    cargaSedesCiudades(){

      let args = {
        ClassName: "VI_Sedes_Ciudades",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 1,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesCiudadesBaseT = response.data;
        }
      });

      args = {
        ClassName: "VI_Sedes_Ciudades",
        Action: "Get",
        Id_Vi_Beneficiario_Tipo: 2,
      };
      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.arrSedesCiudadesBaseD = response.data;
        }
      });

    }

    filtrarSedesCiudadesT(){
      this.arrSedesFechasT = []
      this.arrSedesCiudadesT =[]
      console.log(this.arrSedesT)
      this.ownerForm.get('Id_Vi_SedeT').setValue('')
      this.arrSedesCiudadesT = this.arrSedesCiudadesBaseT.filter((e)=> e.Nombre_Estado == this.ownerForm.get('sedeNombreEstadoT').value)

    }
    filtrarFechasSedesCiudadesT(){
      this.arrSedesFechasT = this.arrFechasT.filter((e)=> e.Nombre_Estado == this.ownerForm.get('sedeNombreEstadoT').value &&
      e.Nombre_Ciudad == this.ownerForm.get('sedeNombreCiudadT').value &&
      e.Id_Vi_Beneficiario_Tipo == 1
      )
    }
    filtrarFechasSedesCiudadesD(){
      this.arrSedesFechasD = this.arrFechasD.filter((e)=> e.Nombre_Estado == this.ownerForm.get('sedeNombreEstadoD').value &&
      e.Nombre_Ciudad == this.ownerForm.get('sedeNombreCiudadD').value &&
      e.Id_Vi_Beneficiario_Tipo == 2
      )
    }
    filtrarSedesCiudadesD(){
      this.arrSedesFechasD = []
      this.arrSedesCiudadesD =[]
      this.ownerForm.get('Id_Vi_SedeD').setValue('')
      this.arrSedesCiudadesD = this.arrSedesCiudadesBaseD.filter((e)=> e.Nombre_Estado == this.ownerForm.get('sedeNombreEstadoD').value)
    }
    confirmarNo(){
      let me = this
      let message = ""
      let args = {
        ClassName: "Vi_Registro_Respuestas_Titulares",
        Action: "Insert",
        Id_Vi_Beneficiario_Tipo:1,
        Email: me.ownerForm.get('email').value,
        Registro: me.ownerForm.get('registro').value,
        // Id_Vi_Sede : 0,
        // Numero_Vacunas : 0,
        Respuesta : 'No'
      };


      this.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          this.confimaSuccess()
          this.showNo = false
        }else{
          message = response.errorMessage
          this.showAlert(message);
        }

      });

    }
    confimaSuccess(){
      let me = this
      this.registro_confirmado = true
      let args = {
        registro: me.ownerForm.get('registro').value,
      };
      me.DataSrv.confirmarPorCorreo(args).subscribe(response => {

        console.log(response)

      })
      let message = "Tu selección se ha confirmado satisfactoriamente,"
      message += " se enviara un correo electrónico con la información de la cita a la dirección de correo indicada."
      this.showAlert(message);
      this.ownerForm.get('numeroVacunasT').setValue(0)
      this.ownerForm.get('numeroVacunasD').setValue(0)
      this.ownerForm.get('Id_Vi_SedeD').setValue("")
      this.ownerForm.get('Id_Vi_SedeT').setValue("")
      this.ownerForm.get('respuestaT').setValue("")
      this.ownerForm.get('respuestaD').setValue("")

      this.arrSedesFechasD = []
      this.arrSedesCiudadesD =[]
      this.arrSedesFechasT = []
      this.arrSedesCiudadesT =[]





    }


    confirmar(){
      let message = ""
      let me = this
      const idViSedeT = parseInt(me.ownerForm.get('Id_Vi_SedeT').value)
      const idViSedeD = parseInt(me.ownerForm.get('Id_Vi_SedeD').value)
      const respuestaD= me.ownerForm.get('respuestaD').value


      // Si el titular dijo que si, verificar que tenga la sede y la fecha establecida
      if(me.ownerForm.get('respuestaT').value == 'Si' && (idViSedeT == 0 || idViSedeT == null || idViSedeT == undefined || isNaN(idViSedeT))){
        message = "Selecciona la sede y la fecha para el titular"
        me.showAlert(message);
        // console.log(me.ownerForm.get('Id_Vi_SedeT').value)
        return
      }


      if(me.arrDependientes.length >0 && (respuestaD == '' || respuestaD == null || respuestaD == undefined)){
        message = "Selecciona si los dependientes reciben la vacuna"
        me.showAlert(message);
        return
      }

      if(respuestaD == 'Si' && me.arrDependientes.filter(e => e.Recibe_Vacuna ===1).length == 0){
        message = "Selecciona al menos un dependiente que reciba la vacuna"
        me.showAlert(message);
        return

      }
      // Si al menos un dependiente recibe la vacuna, debe establecer sede y fecha
      if(respuestaD == 'Si' && (idViSedeD == 0 || idViSedeD == null || idViSedeD == undefined || isNaN(idViSedeD))){
        message = "Selecciona la sede y la fecha para los dependientes"
        me.showAlert(message);
        return

      }

      let args = {
        ClassName: "Vi_Registro_Respuestas_Titulares",
        Action: "Insert",
        Registro: me.ownerForm.get('registro').value,
        Id_Vi_Sede : me.ownerForm.get('Id_Vi_SedeT').value,
        Numero_Vacunas : me.ownerForm.get('respuestaT').value == 'Si' ? 1: 0 ,
        Respuesta : me.ownerForm.get('respuestaT').value,
        Email: me.ownerForm.get('email').value,
      };

      me.DataSrv.executeForCRUD(args).subscribe(response => {
        if(response.success){
          if(me.ownerForm.get('respuestaD').value  == 'Si'  ){
            // console.log(me.arrDependientes)
            me.arrDependientes.map(function(dato){
                let argsDependientes = {
                  ClassName: "Vi_Registro_Respuestas_Dependientes",
                  Action: "Insert",
                  Registro: me.ownerForm.get('registro').value,
                  Id_Vi_Registro_Incisos: dato.Id_Vi_Registro_Incisos,
                  Recibe_Vacuna : dato.Recibe_Vacuna,
                  Id_Vi_Sede : me.ownerForm.get('Id_Vi_SedeD').value,
                };

                me.DataSrv.executeForCRUD(argsDependientes).subscribe(response => {
                  console.log(response)
                })

            });
          }
          me.confimaSuccess()
        }else{
          message = response.errorMessage
          me.showAlert(message);
        }

      });

    }

    showAlert(message){
      this.dialog.open(ConfirmComponent, {
        data: {
          mensaje: message
        }
      });
    }
    setVacunasD(){
      this.totalVacunas = this.totalVacunas + 1 //+ parseInt(this.ownerForm.get('numeroVacunasD').value )
    }
    activaSeleccionT(ele){

      this.showNo = ele == 'No'

      this.seleccionado = true
      this.seleccionT_si = ele == 'Si'
      this.totalVacunas = 0
      this.ownerForm.get('sedeNombreEstadoT').setValue('')
      if(ele == 'Si'){
        this.totalVacunas = 1
        this.arrSedesFechasT = []
        this.arrSedesCiudadesT =[]
        this.ownerForm.get('Id_Vi_SedeT').setValue('0')
        this.ownerForm.get('numeroVacunasT').setValue('0')
      }
      // // console.log("window.scroll")
      // this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
      // document.getElementById('point_2').scrollIntoView();
    }
    gardarDependientes(Id_Vi_Registro_Incisos){
      let me = this
      let recibeVacuna = 0
      me.arrDependientes.map(function(dato){
        if( parseInt(dato.Id_Vi_Registro_Incisos) === parseInt(Id_Vi_Registro_Incisos)){
           if(parseInt(dato.Recibe_Vacuna) === 0){
            dato.Recibe_Vacuna = 1
            // me.setVacunasD()
           }else{
            dato.Recibe_Vacuna = 0
            // me.totalVacunas =  me.totalVacunas- 1
           }
        }
        recibeVacuna = dato.Recibe_Vacuna
        me.totalVacunas =    me.arrDependientes.filter(e => e.Recibe_Vacuna ===1).length + 1
        // console.log( me.arrDependientes.filter(e => e.Recibe_Vacuna ===1).length)
        return dato;
      });

    }
    activaSeleccionD(ele){
      this.seleccionado = true
      this.seleccionD_si = ele == 'Si'
      this.totalVacunas = 1
      this.arrSedesFechasD = []
      this.arrSedesCiudadesD =[]
      this.ownerForm.get('Id_Vi_SedeD').setValue('')
      this.ownerForm.get('numeroVacunasD').setValue('0')
      this.ownerForm.get('sedeNombreEstadoD').setValue('')



    }
    checkEmail(){
      let me = this
      let email = this.ownerForm.get('email').value


      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)){
        this.emailIsValid = true

        if(email.toLowerCase().indexOf("hsbc")> -1){
          this.emailIsValid = false
          me.messageError = "No utilices el correo institucional, en su lugar utiliza un email personal."
        }

       } else {
        this.emailIsValid = false
        me.messageError = "¡La dirección de email es incorrecta!."
       }

       if(!this.emailIsValid){
        this.showAlert(me.messageError );
        return
       }



    }
    resetRagistroConsultado(){
      this.registro_consultado = false
      this.seleccionD_si = false
      this.seleccionT_si = false
      this.registro_valido = false
      this.registro_consultado = false
      this.registro_confirmado = false
    }
    verificarNumeroRegistro() {
      var me = this;
      this.registro_consultado = false
      this.registro_confirmado = false
      this.registro_valido = false
      this.seleccionD_si = false
      this.seleccionT_si = false
      this.isLoading = true
      if(!me.emailIsValid ){
        this.showAlert(me.messageError );
        this.isLoading= false
        return
      }
      return new Promise(resolve => {
        let args = {
          ClassName: "VI_Registros",
          Action: "Get",
          Registro: this.ownerForm.get('registro').value,
        };

        me.DataSrv.executeForCRUD(args).subscribe(response => {

          let argsVerifica = {
            ClassName: "Vi_Verifica_Disponibilidad",
            Action: "Get",
            Registro: this.ownerForm.get('registro').value,
          };

          me.DataSrv.executeForCRUD(argsVerifica).subscribe(responseVerifica => {
            if(!responseVerifica.success){
              this.isLoading= false
              this.showAlert(responseVerifica.errorMessage );

              return
            }

            let maximo =  responseVerifica.data[0].Vacunas_Disponibles > 9 ? 9 : responseVerifica.data[0].Vacunas_Disponibles
            for(var i = 1 ; i <= maximo ; i ++){
              this.arrVacunas.push(i)
            }
            this.registro_valido  = response.success
            if(response.success){
              let args = {
                ClassName: "Vi_Registro_Respuestas",
                Action: "Get",
                Confirmada: 1,
                Registro: this.ownerForm.get('registro').value
              };
              me.DataSrv.executeForCRUD(args).subscribe(responseRespuesta => {
                this.isLoading=false
                // console.log(responseRespuesta)
                if(responseRespuesta.success){
                  this.registro_confirmado = true

                  let args = {
                    registro: me.ownerForm.get('registro').value,
                  };
                  me.DataSrv.confirmarPorCorreo(args).subscribe(response => {
                    console.log(response)

                  })

                  let message = 'El registro ' +  this.ownerForm.get('registro').value + '  ya fue confirmado con anterioridad.'
                  message += ' Se enviara un correo electrónico con la información de la cita a la dirección registrada previamente.'
                  this.showAlert(message);
                }
                this.registro_consultado = true
              });
            }else{
              this.registro_consultado = true
              this.isLoading= false
            }
            this.cargaDependientes()
          })


          resolve(response);
        });
      })
    }

    createOwner(value){
      // // console.log(value)
    }

}
