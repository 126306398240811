<!-- Resources -->


<div class="container-login" [hidden]="IsAdmin">
  <h2>Vacuna Influenza</h2>
  <h3>Para acceder a esta sección necesitas usuario y contraseña</h3>
  <form [formGroup]="ownerForm" autocomplete="off" novalidate (ngSubmit)="acceder()" >
  
    <div class="card-container-login">
      <div class="seccion">
        <mat-form-field appearance="fill">
          <mat-label>Usuario</mat-label>
          <input formControlName="usuario" matInput placeholder="">
        </mat-form-field>
      </div>
      <div class="seccion">
        <mat-form-field appearance="fill">
          <mat-label>Contraseña</mat-label>
          <input type="password" formControlName="password" matInput placeholder="">
        </mat-form-field>
      </div>
      <div class="error" *ngIf="showError">Los datos no son correctos </div>
      <div class="seccion">
        <button class="card" rel="noopener" (click)="acceder()"> Acceder</button>
      </div>

    </div>
  </form>

</div>
<div  [hidden]="!IsAdmin">

  <div class="container-grid mat-elevation-z8">
    
      <mat-toolbar>
        <span>Consulta de registros</span>
        <span class="example-spacer"></span>
        <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
  
        <span class="example-spacer"></span>
        <button mat-icon-button class="btn-tool-bar" aria-label="" (click)="getData()">
          <img src="assets/ico_refrescar.gif" />  
        </button>&nbsp;&nbsp;
        
        <button mat-icon-button class="btn-tool-bar" aria-label="" (click)="descargar()" >
          <img src="assets/ico_excel.gif" /> 
        </button>
      </mat-toolbar>
    <table mat-table [dataSource]="dataSource" matSort>
  
  
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="Registro">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Registro </th>
        <td mat-cell *matCellDef="let element"> {{element.Registro}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="Respuesta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Respuesta </th>
        <td mat-cell *matCellDef="let element"> {{element.Respuesta}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="Ciudad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
        <td mat-cell *matCellDef="let element"> {{element.Ciudad}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="Fecha">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element.Fecha}} </td>
      </ng-container>
  
  
      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  <h2 *ngIf="!loading"> {{casos}} Registros confirmados para la aplicación de la vacuna influenza de {{total}}
    Registros. Faltan {{faltan}}</h2>
    <div class="casos-x-respuestas">
      <h1>Casos por tipo de respuesta</h1>
      <div *ngFor="let item of arrCasosXRespuesta; let i = index" style="display: flex;">
        <div style="flex: 1;text-align: left "> Respondieron {{item.Respuesta}}</div><div style="flex: 1;">  {{item.Casos}} personas  </div>
  
      </div>
    </div>
    <div class="casos-x-sede">
      <h1>Casos por  sede</h1>
      <div *ngFor="let item of arrCasosXSede; let i = index"  style="display: flex;">
        <div style="flex: 1 "> {{i+1}}</div>
        <div style="flex: 2;text-align: left "> {{item.Estado}} | {{item.Ciudad}}</div><div style="flex: 1;">  {{item.Casos}} personas  </div>
      </div>
    </div>  
</div>