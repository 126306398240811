import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders ,HttpParams  } from '@angular/common/http';
import { ConstantesService } from '../services/constantes.services'

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ReponseResult } from '../models/reponse_result.model';

@Injectable({providedIn: 'root'})
export class DataService {
  constructor(
    private httpClient: HttpClient,
    private Constantes: ConstantesService
  ) { }


  executeForCRUD(args: any): Observable<ReponseResult> {
    let params = '';
    args.UsuarioAdd = 1
    args.UsuarioUMod = 1
    args.UsuarioDel = 1
    args.db = "Prometeo_Redes"
    for(let key in args){
        params += key +'=' +args[key] +'&'
    }
    params = args

    const formData = new HttpParams({
      fromObject: args
    });


    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    let options = {
      headers: httpHeaders,
    };
    let path = "/api/Nf_Data/ExecuteForCRUD";
    let url = this.Constantes.FrontBack_Endpoint + path ;
    return this.httpClient.post<ReponseResult>(url, formData, options);
  }

  confirmarPorCorreo(args: any): Observable<ReponseResult> {
    let params = '';
    args.UsuarioAdd = 1
    args.UsuarioUMod = 1
    args.UsuarioDel = 1
    args.db = "Prometeo_Redes"
    for(let key in args){
        params += key +'=' +args[key] +'&'
    }
    params = args

    const formData = new HttpParams({
      fromObject: args
    });


    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    let options = {
      headers: httpHeaders,
    };
    let path = "/vi/confirma_vacunas";
    let url = this.Constantes.FrontBack_Endpoint + path ;

    return this.httpClient.post<ReponseResult>(url, formData, options);
  }

  executeAPI(args: any): Observable<ReponseResult> {
    let me = this;
    // let params = '';
    // for(let key in args){
    //     params += key +'=' +args[key] +'&'
    // }
    // params += 'UsuarioAdd=1&UsuarioUMod=1&UsuarioDel=1'
    // console.log(params)
    // //params = args
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    let url = this.Constantes.FrontBack_Endpoint + "/api/fb/executeAPI";
    return this.httpClient.post<ReponseResult>(url, args, options);
  }

  executeAPICotiza(args: any): Observable<ReponseResult> {
    let me = this;
    args.UsuarioAdd = 1
    args.UsuarioUMod = 1
    args.UsuarioDel = 1
    // let params = '';
    // for(let key in args){
    //     params += key +'=' +args[key] +'&'
    // }
    // params += 'UsuarioAdd=1&UsuarioUMod=1&UsuarioDel=1'
    // params = args
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    let url = this.Constantes.FrontBack_Endpoint_Cotiza + "/api/fb/executeAPI";
    return this.httpClient.post<ReponseResult>(url, args, options);
  }


  // executeAPI(args: any): Observable<ReponseResult> {
  //   let me = this;
  //   // let endPoint = args.endPoint ||  Constantes.FrontBack_Endpoint
  //   // let   bearer = args.bearer || Constantes.Bearer.access_token
  //   let params = '';
  //   for(let key in args){
  //       params += key +'=' +args[key] +'&'
  //   }
  //   params += 'UsuarioAdd=1&UsuarioUMod=1&UsuarioDel=1'

  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let url = this.Constantes.FrontBack_Endpoint + "/api/" +  args.Action;
  //   return this.httpClient.post<ReponseResult>(url, params, options);
  // }


}
