import { AfterViewInit, Component, ViewChild,OnInit} from '@angular/core';

import { ConstantesService } from '../../services/constantes.services';
import { DataService } from '../../services/data.service';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export interface PeriodicElement {

  Ciudad: string,
  Estado: string,
  Id_Vi_Registro_Respuesta: Number,
  Id_Vi_Sede: Number,
  Registro: string,
  Respuesta: string,
  Fecha: string,

}
let ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements AfterViewInit  {
  public ownerForm: FormGroup;
  loading = false;
  IsAdmin =false;
  casos= 0;
  total = 1199;
  faltan = 0;
  displayedColumns: string[] = [  'Registro','Respuesta','Estado', 'Ciudad','Fecha'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  arrCasosXRespuesta = [];
  arrCasosXSede = [];
  showError = false
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private Constantes: ConstantesService,
    private DataSrv: DataService,

  ) {

    this.getData();
  }
  getData(){
    let args = {
      ClassName: "Vi_Registro_Respuestas",
      Action: "Get",
    };
    this.loading = true;
    this.DataSrv.executeForCRUD(args).subscribe(response => {
      //console.log(response)
      if(response.success){
        this.casos = parseInt(response.total);
        this.faltan = this.total - this.casos

        this.loading = false;
        this.dataSource = new MatTableDataSource<PeriodicElement>([...response.data]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getCasosXRespuesta();
        this.getCasosXSede();

      }
    });
  }

  getCasosXRespuesta(){
    let args = {
      ClassName: "Vi_Resumen_Respuestas_X",
      Action: "Respuesta",
    };
    this.loading = true;
    this.DataSrv.executeForCRUD(args).subscribe(response => {
      if(response.success){
        this.loading = false;
        this.arrCasosXRespuesta =  response.data
      }
    });
  }
  getCasosXSede(){
    let args = {
      ClassName: "Vi_Resumen_Respuestas_X",
      Action: "Sede",
    };
    this.loading = true;
    this.DataSrv.executeForCRUD(args).subscribe(response => {
      if(response.success){
        this.loading = false;
        this.arrCasosXSede=  response.data
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
    this.ownerForm = new FormGroup({

      // usuario: new FormControl('admin', [Validators.required]),
      // password: new FormControl('VerResultados=Si', [Validators.required]),

      usuario: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),

    });
  }
  acceder(){

    this.IsAdmin = this.ownerForm.get("usuario").value == 'admin' &&  this.ownerForm.get("password").value == 'VerResultados=Si';
    this.showError =  !this.IsAdmin
  }
  descargar(){
    let args = {
      Id_Oficina:"2",
      Id_Usuario:"1",
      Id_Usuario_Add:"1",
      UsuarioAdd:"1",
      UsuarioDel:"1",
      user:"1",
      Id_Usuario_Modificacion:"1",
      UsuarioUMod:"1",
      sort:JSON.stringify( {"property":"Id_Vi_Registro_Respuesta","direction":"ASC"}),
      GridViewID:"7218",
      ClassName:"Vi_Registro_Respuestas",
      viewType:"",
      qs:[],
      Action:"Nf_GridView/ExportarToExcel",
      db:"Prometeo",
      pageSize:"1000"
    };
    this.loading = true;
    this.DataSrv.executeAPI(args).subscribe(response => {
      this.loading = false;
      //console.log(response)
      if(response.success){

        var linkElement = document.createElement('a');
        try {

            var filename = this.Constantes.FrontBack_Endpoint +"/api/fb/getFile?fileName=" + response.data[0].fileName
            console.log(filename)
            linkElement.setAttribute('href', filename);
            linkElement.setAttribute("download", filename);
            var clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
            //console.log(ex);
        }

      }
    });

  }

}
