import { Injectable } from '@angular/core';

//import * as moment from 'moment';
//import 'moment/locale/pt-br';

@Injectable({providedIn: 'root'})
export class ConstantesService {
    constructor() {}
    BROKER="SUSEGURO"
    //BROKER="ADELANTE"
    //BROKER="INNOVASYS"
    Id_Cotizacion_Campania = "1";
    Id_Cotizacion_Campania_PORTAL = "1";
    Id_Persona_Vendedor = 115521
    RTE =  'prod'
    setDefaultValues = false
    VER = "1.0.0"
    PLATFORM = "WEB"
    Id_App = 1
    USER :any
    AGENTE:any
    ROLES: any[]
    Ticket_Estatus_PENDIENTE_DE_ACEPTAR = 1
    Ticket_Estatus_CERRADO_PENDIENTE_DE_VALIDAR = 2
    Ticket_Estatus_EN_PROCESO = 3
    Ticket_Estatus_CERRADO_VALIDADO = 4
    Ticket_Estatus_CERRADO_CANCELADO = 5
    Ticket_Estatus_NO_PROCEDENTE = 6
    Ticket_Estatus_RECHAZADO = 7
    Ticket_Estatus_EN_ESPERA_DE_OTRO_TICKET = 8
    Ticket_Estatus_SOLICITUD_DE_INFORMACION = 9
    Ticket_Estatus_REASIGNADO = 10
    base_calculo_UMAM = 2220.3
    tarifa_deducible_x_accidente = 577

    // dataBaseName = 'Prometeo_SS'
    // runTimeMode = 'SSQA'
    Bearer =  "";

    //--Id_Tarea_Tipo	Nombre
    Id_Tarea_Tipo_ACEPTAR_VALIDAR=	1;
    Id_Tarea_Tipo_CITA=	2;
    Id_Tarea_Tipo_LLAMADA=	3;
    Id_Tarea_Tipo_ENVIAR_CORREO= 4;
    Id_Tarea_Tipo_RECORDATORIO=	5;

  // CONSTANTES DE TIPOS TICKET ;";
    Id_Ticket_Tipo_TOMATURNO = 1;
    Id_Ticket_Tipo_PROSPECTACION = 2;
    Id_Ticket_Tipo_SOLICITUD_DE_PAGO = 5;
    Id_Ticket_Tipo_VENTANILLA_UNICA = 6;
    Id_Ticket_Tipo_EVALUACION_CEDULA = 7;
    Id_Ticket_Tipo_CITA_SS = 8;

    Id_Ticket_Flujo_SOLICITUD_DE_PAGO_O_REEMBOLSO=30;
    Id_Ticket_Flujo_GASTO_A_COMPROBAR=31;
    Id_Ticket_Flujo_ANTICIPO_A_PROVEEDORES =32;
    Id_Ticket_Flujo_COTIZACIONES_PORTAL =  35;
    Id_Ticket_Flujo_EVALUACION_CEDULAS = 37;
    Id_Ticket_Flujo_AGENDA_SS_CITA_PROSPECTACION = 38;

    //Id_Ramo
    Id_Ramo_VIDA = 1;
    Id_Ramo_GASTOS_MEDICOS  = 2;
    Id_Ramo_AUTOS  = 3;
    Id_Ramo_RC_MEDICOS  = 7;
    Id_Ramo_CASA_HABITACION  = 4;





    SENDER_ID = 1025982477357
    FrontBack_Endpoint_Cotiza = 'https://app.vacunainfluenza.com.mx'
    FrontBack_Endpoint = 'https://app.vacunainfluenza.com.mx'
    // FrontBack_Endpoint_Cotiza = 'https://5ab4-2806-102e-3-1319-146a-f1a7-904e-fb31.ngrok-free.app'
    // FrontBack_Endpoint = 'https://5ab4-2806-102e-3-1319-146a-f1a7-904e-fb31.ngrok-free.app'

    Bearer_Hdi =  {
      access_token:  ''
    }

}
