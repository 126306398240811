
<div #myElem class="content" role="main">

    <div class="card-container">
        <!-- Resources -->
        <h3 *ngIf="!periodoAbierto" style="font-size: 16px;margin:5px">El periodo de registro está cerrado</h3>
        <h3 *ngIf="periodoAbierto" style="font-size: 16px;margin:5px">Para registrar tu solicitud de vacunas,<br/> proporciona la siguiente información:</h3>


        <form [formGroup]="ownerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(ownerForm.value)" >

          <div *ngIf="periodoAbierto" style="width:100%;padding:10px;">
              <div class="pregunta">Número de registro ( 8 digitos)</div>
              <mat-form-field appearance="fill" style="width: 90%;">
                  <mat-label>Número Registro</mat-label>
                  <input formControlName="registro" matInput placeholder="" (focus)="resetRagistroConsultado()" (keypress)="resetRagistroConsultado()" >

              </mat-form-field>
              <div class="pregunta">Correo electrónico personal</div>
              <mat-form-field appearance="fill" style="width: 90%;">
                  <mat-label>Correo Electrónico</mat-label>
                  <input formControlName="email" matInput placeholder="" (blur)="checkEmail()" (focus)="resetRagistroConsultado()" (keypress)="resetRagistroConsultado()" >
              </mat-form-field>

              <br/>
              <div style="word-wrap: break-word;width:90%">El correo electrónico es necesario para que recibas confirmación de la cita
                  y los certificados de  aplicación de la vacuna. El correo que proporciones debe ser personal. <b>NO UTILICES EL CORREO INSTITUCIONAL</b></div>
              <br />
          </div>
          <div  *ngIf="periodoAbierto" style="display: flex;flex-direction:row;  align-items: center;justify-content: center;">

            <div  style=" width:50%;text-align: left;padding: 10px;" >
              <mat-progress-spinner
              class="progress-spinner"
              mode="indeterminate"
              [diameter]="50"
              *ngIf="isLoading"
              >
              </mat-progress-spinner>
            </div>
              <div  style=" width:50%;text-align: right;padding: 10px;" >
                  <button style="float: right;" mat-raised-button color="primary" type="button" *ngIf="!registro_consultado"  (click)="verificarNumeroRegistro()">Continuar</button>
              </div>

          </div>
          <div *ngIf="!registro_valido && registro_consultado" class="registro-novalido">No eres elegible para la vacunación</div>
          <div *ngIf="registro_valido && registro_consultado && !registro_confirmado" >

              <div class="seccion">
                  <div class="pregunta">¿Deseas la vacuna?</div>
                  <div class="casrd">
                      <mat-radio-group class="example-radio-group" formControlName="respuestaT">
                          <mat-radio-button  [value]="season" *ngFor="let season of seasons" (change)="activaSeleccionT(season)">
                              {{season}}
                          </mat-radio-button>

                      </mat-radio-group>
                  </div>
              </div>
              <div class="seccion" *ngIf="showNo">
                  <a class="card" target="_blank" rel="noopener" (click)="confirmarNo()">


                      <span>&nbsp;&nbsp;Confirma que no quieres vacuna&nbsp;&nbsp;</span>
                  </a>
              </div>
              <div *ngIf="seleccionT_si  ">
                  <div class="seccion" >
                      <div style="margin-top: 10px; margin-bottom: 20px;">
                          Vacuna Titular : 1
                      </div>
                      <div class="pregunta">Selecciona la sede</div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Estado</mat-label>
                              <mat-select formControlName="sedeNombreEstadoT" (selectionChange)="filtrarSedesCiudadesT()">
                                  <mat-option *ngFor="let sede of arrSedesEstadosT" [value]="sede.Nombre_Estado">
                                  {{sede.Nombre_Estado}}
                                  </mat-option>
                              </mat-select>

                              <!-- <mat-select>
                                  <mat-option  *ngFor="let sede of arrSedesEstados">{{sede.Nombre_Estado}}</mat-option>
                              </mat-select> -->
                              <mat-hint align="end">Selecciona tu Estado ^</mat-hint>
                          </mat-form-field>
                      </div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Ciudad / Sede</mat-label>
                              <mat-select formControlName="sedeNombreCiudadT" (selectionChange)="filtrarFechasSedesCiudadesT()">
                                  <mat-option *ngFor="let sede of arrSedesCiudadesT" [value]="sede.Nombre_Ciudad">
                                  {{sede.Nombre_Ciudad}}
                                  </mat-option>
                              </mat-select>

                              <mat-hint align="end">Selecciona tu ciudad / sede </mat-hint>
                          </mat-form-field>
                      </div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Fecha</mat-label>
                              <mat-select formControlName="Id_Vi_SedeT" >
                                  <mat-option *ngFor="let sede of arrSedesFechasT" [value]="sede.Id_Vi_Sede">
                                  {{sede.Fecha}}
                                  </mat-option>
                              </mat-select>

                              <mat-hint align="end">Selecciona la fecha </mat-hint>
                          </mat-form-field>
                      </div>

                  </div>

                  <!-- Estimado colega, agradecemos tu interés, desafortunadamente los mil registros ya se concluyeron -->

                  <div class="seccion" *ngIf="arrDependientes.length > 0">
                      <div class="pregunta">¿Deseas la vacuna para tus beneficiarios?</div>


                      <div class="casrd">
                          <mat-radio-group class="example-radio-group" formControlName="respuestaD">
                              <mat-radio-button   [value]="season" *ngFor="let season of seasons" (change)="activaSeleccionD(season)">
                                  {{season}}
                              </mat-radio-button>

                          </mat-radio-group>
                      </div>
                  </div>

                  <div class="seccion" *ngIf="seleccionD_si  ">
                      <div class="pregunta">Indícanos para quién  requieres vacuna</div>
                      <div *ngFor="let dependiente of arrDependientes" >
                          <mat-checkbox (checked)="dependiente.Recibe_Vacuna" class="example-margin"  (change)="gardarDependientes(dependiente.Id_Vi_Registro_Incisos)">
                              {{dependiente.Nombre}}
                          </mat-checkbox>
                      </div>
                      <br /><br />
                      <div class="pregunta">Selecciona la sede de vacunación para tu beneficiarios </div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Estado</mat-label>
                              <mat-select formControlName="sedeNombreEstadoD" (selectionChange)="filtrarSedesCiudadesD()">
                                  <mat-option *ngFor="let sede of arrSedesEstadosD" [value]="sede.Nombre_Estado">
                                  {{sede.Nombre_Estado}}
                                  </mat-option>
                              </mat-select>



                              <mat-hint align="end">Selecciona tu Estado ^</mat-hint>
                          </mat-form-field>
                      </div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Ciudad / Sede</mat-label>
                              <mat-select formControlName="sedeNombreCiudadD" (selectionChange)="filtrarFechasSedesCiudadesD()" >
                                  <mat-option *ngFor="let sede of arrSedesCiudadesD" [value]="sede.Nombre_Ciudad">
                                  {{sede.Nombre_Ciudad}}
                                  </mat-option>
                              </mat-select>

                              <mat-hint align="end">Selecciona tu ciudad / sede </mat-hint>
                          </mat-form-field>
                      </div>
                      <div >
                          <mat-form-field appearance="fill">
                              <mat-label>Fecha</mat-label>
                              <mat-select formControlName="Id_Vi_SedeD" >
                                  <mat-option *ngFor="let sede of arrSedesFechasD" [value]="sede.Id_Vi_Sede">
                                  {{sede.Fecha}}
                                  </mat-option>
                              </mat-select>

                              <mat-hint align="end">Selecciona fecha </mat-hint>
                          </mat-form-field>
                      </div>
                  </div>

                  <div class="seccion" *ngIf="true">
                      <div>
                          Por favor valida que el número total de vacunas para ti y tus beneficiarios sea correcto
                      </div>
                      <br /><br />
                      <a mat-raised-button color="primary" class="card" target="_blank" rel="noopener" (click)="confirmar()">


                          <span>Confirma que el total de vacunas es :{{totalVacunas}}</span>

                      </a>
                  </div>
              </div>
          </div>

        </form>
    </div>
    <div  style="width:50%; text-align: right;padding: 10px; " >



  </div>

</div>

<div  style="width:50%; text-align: right;padding: 10px; " >{{ver}}</div>
